import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { LanguageService } from 'src/app/services/language/language.service';
import { ThisReceiver } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations:[
    trigger("animateMenu",[
      transition(":enter",[
        query("*", [
          style({opacity: 0, transform: "translateY(-50%)"}),
          stagger(50,[
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity: 1, transform: "none"}))
          ])
        ])
      ])
    ])
  ]
})



export class HeaderComponent implements OnInit {

  responsiveMenuVisible: Boolean = false;
  pageYPosition: number;
  languageFormControl: UntypedFormControl= new UntypedFormControl();
  cvName: string = "";
  activeMenuItem: string = '';
  language: string;
  contactsRoute: string[];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {

    this.languageFormControl.valueChanges.subscribe(val => this.languageService.changeLanguage(val))

    this.languageFormControl.setValue(this.languageService.language)
    this.route.params.subscribe(params => {
      this.language = params['language'] || 'es';
      this.updateContactRoute();
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setActive(' ');
  }
  
  scroll(el) {
    if (document.getElementById(el)) {
      const elementPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: elementPosition, behavior: 'smooth' });
    } else {
      this.router.navigate(['/',this.language ]).then(() => {
        const elementPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: elementPosition, behavior: 'smooth' });
      });
    }
    this.responsiveMenuVisible = false;
  }
  
  setActive(menuItem: string) {
    this.activeMenuItem = menuItem;    
  }
  
  navigateToRoute(): void {
    this.router.navigate(this.contactsRoute);    
    this.scrollToTop();
  }

  updateContactRoute(): void {
    if (this.language === 'es') {
      this.contactsRoute = ['/', this.language, 'nosotros'];
    } 
    if  (this.language === 'en') {
      this.contactsRoute = ['/', this.language, 'about-Us'];
    }    
  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
    getScrollPosition(event) {
        this.pageYPosition=window.pageYOffset
    }

    changeLanguage(language: string) {
      this.languageFormControl.setValue(language);
      this.setActive(' ');
      this.isDropdownOpen = false; // Cierra el dropdown al seleccionar
    }

    redirectToExternalLink(){
      window.location.href = 'https://app.prestaya-latam.com/sign-up';
    }
    isDropdownOpen = false;

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }

// @HostListener('document:click', ['$event'])
// onClickOutside(event: MouseEvent) {
//   const clickedInside = (event.target as HTMLElement).closest('aside');
//   if (!clickedInside && this.responsiveMenuVisible) {
//     this.responsiveMenuVisible = false;
//   }
// }

  toggleMenu() {
    this.responsiveMenuVisible = !this.responsiveMenuVisible;
  }
  closeMenuOutside(event: MouseEvent): void {
    if (this.responsiveMenuVisible) {
      this.responsiveMenuVisible = false;
    }
    event.stopPropagation();
  }

  @ViewChild('popupDialog') popupDialog: ElementRef<HTMLDialogElement>;

  openPopup() {
    this.popupDialog.nativeElement.showModal();
  }

  closePopup() {
    this.popupDialog.nativeElement.close();
  }
}
