import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './banner/banner.component';
import { ContactComponent } from './contact/contact.component';
import { JobsComponent } from './jobs/jobs.component';
import { MoreProyectsComponent } from './more-proyects/more-proyects.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { NgbCarouselModule, NgbModule, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MisionVisionComponent } from '../general/mision-vision/mision-vision.component';
import { CardVentajasComponent } from '../general/card-ventajas/card.component';
import { CardInvestComponent } from '../general/card-data/card.component';
import { CardLendComponent } from '../general/card-lend/card.component';
import { CardComentarioComponent } from '../general/card-comentarios/card-comentario.component';
import { ButtonUpComponent } from '../general/buttonUp/buttonUp.component';
import { MyChartComponent } from '../general/graph/my-chart.component';
import { QuestionsComponent } from './frequent-questions/frequent-questions.component';
import { BrowserModule } from '@angular/platform-browser';
import { PopupComponent } from '../general/popup/popup.component';
import { AboutUsComponent } from './aboutUs/aboutUs.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StepsInvestComponent } from './stepsInvert/stepsInvert.component';
import { SectionImageComponent } from '../general/sectionImage/sectionImage.component';
import { PoliticasPrivacidadComponent } from '../general/politicasPrivacidad/politicasPrivacidad.component';
import { SectionAppComponent } from '../general/sectionApp/sectionApp.component';
import { LendSectionComponent } from './lend-section/lend-section.component';
import { SectionEmpresasComponent } from '../general/sectionEmpresas/sectionEmpresas.component';
import { SectionVentajasComponent } from '../general/sectionVentajas/sectionVentajas.component';
import { SectionNosotrosComponent } from '../general/sectionNosotros/sectionNosotros.component';


export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [    
    HomeComponent,
    BannerComponent,
    AboutComponent,  
    AboutUsComponent,  
    JobsComponent,        
    CardInvestComponent,        
    LendSectionComponent,
    CardLendComponent,  
    SectionVentajasComponent,
    SectionNosotrosComponent,
    SectionAppComponent,
    SectionImageComponent,
    PoliticasPrivacidadComponent,
    CardComentarioComponent,   
    QuestionsComponent, 
    SectionEmpresasComponent,
    ButtonUpComponent,     
    PopupComponent, 
    MyChartComponent,
    CardVentajasComponent,
    StepsInvestComponent,
    MisionVisionComponent,
    ProyectsComponent,
    MoreProyectsComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgbNavModule,  
    NgbCarouselModule,  
    NgbModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ]
})
export class HomeModule { }
