import { Component, Input } from "@angular/core";
import { Chart } from "chart.js/auto"; // Import auto for version compatibility

@Component({
  selector: "app-my-chart",
  templateUrl: "./my-chart.component.html",
  styleUrls: ["./my-chart.component.scss"]
})
export class MyChartComponent {
  @Input() amount: number;
  @Input() time: number;
  @Input() total: number;
  @Input() percentage: number;

  chart: Chart;
  fullDataInvest: number[] = []; // Almacena los valores completos de inversión

  ngOnInit(): void {
    this.createChart();
  }

  public createChart(): void {
    const labels = this.generateLabels();
    const dataInvest = this.generateReducedData(this.amount); // Valor reducido para mostrar
    const dataTotalInvest = this.generateData2(this.total); // Valor completo para rendimiento

    this.fullDataInvest = this.generateData(this.amount); // Valor completo para tooltips

    this.chart = new Chart("MyChart", {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Inversión",            
            data: dataInvest, // Muestra valores reducidos
            backgroundColor: "#16936F",
            borderWidth: 1,
            borderColor: "#16936F",
            borderRadius: 7,
          },
          {
            label: "Rendimiento",                        
            data: dataTotalInvest, // Muestra valores completos
            backgroundColor: "#124B60",
            borderWidth: 1,
            borderColor: "#124B60",
            borderRadius: 7,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, // Esto permite que la altura se ajuste automáticamente
        plugins: {
          legend: {
            position: "top",
            labels: {
              font: {
                size: 14, 
                weight: 'bold'
              }
            }
          },
          title: {
            display: true,
            text: `Rendimiento: ${this.percentage}%`,
            font: {
              size: 16,
              weight: 'bold'
            }
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                const dataIndex = context.dataIndex;

                // Para "Inversión", mostramos el valor completo en el tooltip
                if (datasetIndex === 0) {
                  return `Valor completo: ${this.fullDataInvest[dataIndex]}`;
                } else {
                  return `Valor: ${context.raw}`; // Muestra el valor real para "Rendimiento"
                }
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 0,
              },              
            }
          },
          x: {
            title: {
              display: true,
              text: 'Mes',
              font: {
                size: 14,
                weight: 'bold'
              }
            },
            ticks: {
              font: {
                size: 12,
              }
            }
          }
        }
      }
    });
  }

  public generateLabels(): string[] {
    const labels: string[] = [];
  
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || (i % 3 === 0) || i === this.time) {
        labels.push(`${i}`);
      }
    }
  
    return labels;
  }
  
  // Genera datos completos para la inversión
  public generateData(value: number): number[] {
    const data: number[] = [];
  
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || (i % 3 === 0) || i === this.time) {
        data.push(value); // Valor completo
      }
    }
  
    return data;
  }

  // Genera los datos reducidos (por ejemplo, la mitad del valor original)
  public generateReducedData(value: number): number[] {
    const data: number[] = [];
  
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || (i % 3 === 0) || i === this.time) {
        data.push(value * 0.8 );
      }
    }
  
    return data;
  }

  public generateData2(value: number): number[] {
    const data: number[] = [];
    const increment = value * 0.02;

    for (let i = 1; i <= this.time; i++) {
      const currentValue = value + (increment * i);
      if (i === 1 || (i % 3 === 0) || i === this.time) {
        data.push(parseFloat(currentValue.toFixed(2)));
      }      
    }
  
    return data;
  }
  
  public updateChart(): void {
    this.chart.data.datasets[0].data = this.generateReducedData(this.amount); // Actualiza los datos reducidos
    this.chart.data.datasets[1].data = this.generateData2(this.amount); // Actualiza los datos completos
    this.chart.data.labels = this.generateLabels();
    this.chart.options.plugins.title.text = `Rendimiento: ${this.percentage}%`;
    this.chart.update();
  }

}
