import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import * as stepsData from '../../../../assets/i18n/es.json';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnDestroy {
  commentForm: FormGroup;
  private googleFormUrl = 'https://docs.google.com/forms/u/1/d/e/1FAIpQLSd-4Gzz9EYOO5rPfWyHJYsDIKna63VLxOvTVBzi1RTiFd1LUw/formResponse';
  activeStep = 0;
  intervalId: any;
  currentIndexIntervalId: any;
  groupedComentarios: any[][] = [];  
  currentGroupIndex: number = 0;
  steps = (stepsData as any).default;
  message: string = '';
  isSubmitting: boolean = false;
  resizeListener: any;
  translate: any;
  comentarios: any[];

  currentIndex: number = 0;
  isAnimating: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {     
  }

  ngOnInit(): void {
    // Inicia el intervalo para cambiar el paso activo (ya existente)
    this.intervalId = setInterval(() => {
      this.activeStep = (this.activeStep + 1) % 7;
    }, 10000);

    // Obtiene los comentarios y los agrupa
    this.translateService.get('Ventajas.Comentarios').subscribe((data: any[]) => {
      this.comentarios = data;
      this.updateGroupedComentarios(window.innerWidth); // Agrupa los comentarios según el tamaño actual de la ventana

      // Inicia el intervalo para cambiar automáticamente el currentIndex
      this.startAutoSlide();
    });

    // Escucha el cambio de tamaño de la ventana para reagrupar los comentarios
    this.resizeListener = this.renderer.listen('window', 'resize', (event) => {
      this.updateGroupedComentarios(event.target.innerWidth);
    });
  }

  startAutoSlide(): void {
    this.currentIndexIntervalId = setInterval(() => {
      const groupSize = window.innerWidth < 760 ? 1 : 3; // Determinar el groupSize según el tamaño de la pantalla

      if (this.currentIndex + groupSize < this.comentarios.length) {
        this.currentIndex += groupSize;
      } else {
        this.currentIndex = 0; // Reiniciar al comienzo
      }
    }, 5000); // Cambia cada 5 segundos
  }

  updateGroupedComentarios(windowWidth: number): void {
    const groupSize = windowWidth < 760 ? 1 : 3; // 1 grupo si el ancho es menor a 760px, 3 grupos si es mayor
    this.groupedComentarios = this.groupComentarios(this.comentarios, groupSize);

    // Asegurarse de que el currentIndex esté dentro del rango correcto
    if (this.currentIndex >= this.comentarios.length) {
      this.currentIndex = 0; // Reiniciar si el índice está fuera del rango
    }
  }

  groupComentarios(comentarios: any[], size: number): any[][] {
    const grouped = [];
    for (let i = 0; i < comentarios.length; i += size) {
      grouped.push(comentarios.slice(i, i + size));
    }
    return grouped;
  }

  onSubmit(): void {
    if (this.commentForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const formData = this.commentForm.value;
      const formFieldName = 'entry.552491125';

      const params = new HttpParams()
        .set(formFieldName, formData.comment);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      this.http.post(this.googleFormUrl, params.toString(), { headers })
        .subscribe(
          response => {
            this.message = 'Comentario enviado correctamente.';
            this.commentForm.reset();
            this.isSubmitting = false;
            setTimeout(() => this.message = '', 5000);
          },
          error => {
            this.message = 'Comentario enviado correctamente.';
            this.commentForm.reset();
            this.isSubmitting = false;
            setTimeout(() => this.message = '', 5000);
          }
        );
    }
  }

  getPaginationArray(): number[] {
    const groupSize = window.innerWidth < 760 ? 1 : 3;
    return Array(Math.ceil(this.comentarios.length / groupSize)).fill(0).map((_, i) => i);
  }

  setPage(index: number) {
    const groupSize = window.innerWidth < 760 ? 1 : 3;
    this.currentIndex = index * groupSize;
  }

  beforeSlide(event: NgbSlideEvent): void {
    this.isAnimating = true;
  }
  
  afterSlide(event: NgbSlideEvent): void {
    this.isAnimating = false;
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.currentIndexIntervalId) {
      clearInterval(this.currentIndexIntervalId);
    }
    if (this.resizeListener) {
      this.resizeListener();
    }
  }
}
