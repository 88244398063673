import { Component, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],  
})

export class PopupComponent{
  @ViewChild('popupDialog') popupDialog: ElementRef<HTMLDialogElement>;

  openPopup() {
    this.popupDialog.nativeElement.showModal();
  }
  
  @Output() close = new EventEmitter<void>();

  closePopup() {
    this.close.emit();
  }
}
