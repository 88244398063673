<div class="card-comentario">
	<p class="comment-text">"{{ text }}"</p>
	<div class="card-footer">
		<img [src]="imagen"
			alt="Imagen del autor"
			class="author-image">
		<p class="author-name">{{ author }}</p>
		<p class="stars">★★★★★</p>
	</div>
</div>
