<div class="section-app">
	<h1>
		{{"section.AppMobile"|translate}}
	</h1>
	<p class="text-section">
		{{"section.AppMobileText"|translate}}
	</p>
	<div class="container-download">
		<a href="https://play.google.com/store/apps/details?id=com.prestaya.prestaya"
			target="_blank"
			class="play-store-button">
			<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
				alt="Get it on Google Play">
		</a>
		<!-- <a href="#"
			class="play-store-button">
			<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
				alt="Get it on Google Play">
		</a> -->
	</div>
</div>
