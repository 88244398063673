<div class="modal-header">
	<h4 class="modal-title text-center">Muy pronto</h4>
	<button (click)="closePopup()"
		type="button"
		class="close"
		aria-label="Close">
		<span aria-hidden="true"
			class="green-icon">
			&times;
		</span>
	</button>
</div>
<div class="modal-body">
	<img src='assets/images/cuadradomini-sf.png'
		alt="Imagen emergente"
		class="popup-image">
	<p>
		Se habilitarán los registros
	</p>
</div>
<div class="modal-footer">
	<button (click)="closePopup()"
		type="button"
		class="btn btn-outline-dark green-button">
		Cerrar
	</button>
</div>
