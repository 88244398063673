import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core'; // Asegúrate de importar TranslateService
import * as stepsData from '../../../../assets/i18n/es.json';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-steps',
  templateUrl: './stepsInvert.component.html',
  styleUrls: ['./stepsInvert.component.scss']
})
export class StepsInvestComponent implements OnInit, OnDestroy {
  activeStep = 0;
  intervalId: any;  
  groupedComentarios: any[][] = [];
  comentarios: any[] = [];
  currentGroupIndex: number = 0;
  steps = (stepsData as any).default;    
  
  constructor(
    private fb: FormBuilder, private http: HttpClient,
    public analyticsService: AnalyticsService,
    private translateService: TranslateService // Inyectar TranslateService
  ) { }

  ngOnInit(): void {
    // Iniciar el intervalo
    this.intervalId = setInterval(() => {
      this.activeStep = (this.activeStep + 1) % 4; // Restablecer a 0 cuando alcance 6
    }, 10000);    
    this.translateService.get('Ventajas.Comentarios').subscribe((data: any[]) => {
      this.comentarios = data;
      this.groupedComentarios = this.groupComentarios(data, 3);
      this.startCarousel();
    });
  }

  wordCountValidator(minWords: number, maxWords: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const words = control.value ? control.value.trim().split(/\s+/) : [];
      if (words.length < minWords) {
        return { minWords: true };
      } else if (words.length > maxWords) {
        return { maxWords: true };
      }
      return null;
    };
  }
  
  groupComentarios(comentarios: any[], size: number): any[][] {
    const grouped = [];
    for (let i = 0; i < comentarios.length; i += size) {
      grouped.push(comentarios.slice(i, i + size));
    }
    return grouped;
  }

  startCarousel(): void {
    setInterval(() => {
      this.currentGroupIndex = (this.currentGroupIndex + 1) % this.groupedComentarios.length;
    }, 5000); // 5 seconds interval
  }
  
  ngOnDestroy(): void {
    // Limpiar el intervalo cuando se destruya el componente
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  @ViewChild('carousel') carousel!: ElementRef;
  currentIndex = 0;

  ngAfterViewInit() {
    this.updateTransform();
  }


goToStep(index: number) {
    this.activeStep = index;
}

  updateTransform() {
    const carousel = this.carousel.nativeElement as HTMLElement;
    const items = carousel.querySelectorAll('.carousel-item') as NodeListOf<HTMLElement>;
    const itemWidth = items[0].clientWidth;
    const itemsPerView = window.innerWidth >= 768 ? 3 : 1;

    carousel.style.transform = `translateX(-${this.currentIndex * (itemWidth / itemsPerView)}px)`;
  }
  
}
