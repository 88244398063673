import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionVentajas',  
  templateUrl: './sectionVentajas.component.html',
  styleUrl: './sectionVentajas.component.scss'
})
export class SectionVentajasComponent {
 
}
