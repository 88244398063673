import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionApp',  
  templateUrl: './sectionApp.component.html',
  styleUrl: './sectionApp.component.scss'
})
export class SectionAppComponent {  
  
}
