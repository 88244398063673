<!-- <div class="vision-mision-container">
	<div class="vision">
		<h2>{{ title }}</h2>
		<p>{{ subtitle }}</p>
	</div>
	<div class="mision">
		<h2>{{ title2 }}</h2>
		<p>{{ subtitle2 }}</p>
	</div>
</div> -->




<div class="vision-mision-container">
	<div *ngIf="imagePosition === 'left'"
		class="image-container">
		<img [src]="imageUrl"
			class="image"
			alt="Background Image">
	</div>
	<div class="text-container">
		<div class="text-content vision">
			<h2 class="title">{{ title }}</h2>
			<p class="subtitle">{{ subtitle }}</p>
		</div>
		<!-- <div class="text-content mision">
                <h2 class="title">{{ title2 }}</h2>
                <p class="subtitle">{{ subtitle2 }}</p>
            </div> -->
	</div>
	<div *ngIf="imagePosition === 'right'"
		class="image-container">
		<img [src]="imageUrl"
			class="image"
			alt="Background Image">
	</div>
</div>
