import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {}


  ngOnInit(): void {
    // this.analyticsService.sendAnalyticPageView("/inicio", "Se entro a inicio")
    console.log('Current route:', this.route.snapshot.url.map(segment => segment.path).join('/'));

  }

}
