<div class="faq-container">
	<span [innerHTML]='"faq.SubTittle2" | translate'
		class="advantages-title text-center mb-4"></span>
	<h2 [innerHTML]='"AboutMe.MoreInfoButton3" | translate'
		class="text-center mt-4 mb-4"></h2>
	<div *ngFor="let faq of ('faqs' | translate | slice:0:showAll ? 10 : 4); let i = index"
		[ngClass]="{'expanded': expandedIndex === i}"
		class="faq-item">
		<div (click)="toggleAnswer(i)"
			class="faq-question">
			<div class="question-text">{{ faq.question }}</div>
			<span class="icon">
				<i class="material-icons">
					{{ expandedIndex === i ? 'expand_less' : 'expand_more' }}
				</i>
			</span>
		</div>
		<div *ngIf="expandedIndex === i"
			class="faq-answer">
			{{ faq.answer }}
		</div>
	</div>
	<div (click)="toggleView()"
		class="expand-toggle">
		<span class="icon">
			<i class="material-icons icon-final">
				{{ showAll ? 'expand_less' : 'expand_more' }}
			</i>
		</span>
	</div>
</div>
