import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyChartComponent } from '../../general/graph/my-chart.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  @ViewChild(MyChartComponent) myChartComponent: MyChartComponent;
  language: string;
  showTableInvest = false;
  monto: number = 100;
  plazo: number = 1;
  totalInvest: number;
  totalgain: number;
  percentageTotal: number;
  responsiveMenuVisible: boolean = false;
  questionsRoute: string[];
  montoError: string = '';
  plazoError: string = '';
  isValid: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService
  ) {}

  get sliderBackgroundMonto() {
    const percentage = ((this.monto - 100) / (14000 - 100)) * 100;
    return `linear-gradient(to right, rgba(18, 75, 96, 1) ${percentage}%, #ddd ${percentage}%)`;
  }

  get sliderBackgroundPlazo() {
    const percentage = ((this.plazo - 1) / (24 - 1)) * 100;
    return `linear-gradient(to right, rgba(18, 75, 96, 1) ${percentage}%, #ddd ${percentage}%)`;
  }

  updateSliderColor() {
    this.montoError = '';
    this.plazoError = '';
    this.monto = this.monto;
    this.plazo = this.plazo;
  }

  validateInputs() {
    this.isValid = true;
    if (this.monto < 100) {
      this.montoError = 'El monto debe ser mayor a 100';
      this.isValid = false;
    }
    if (this.plazo < 1 || this.plazo > 24) {
      this.plazoError = 'El plazo debe estar entre 1 y 24.';
      this.isValid = false;
    }
    return this.isValid;
  }

  scroll(el) {
    if (document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router
        .navigate(['/home'])
        .then(() =>
          document.getElementById(el).scrollIntoView({ behavior: 'smooth' })
        );
    }
    this.responsiveMenuVisible = false;
  }

  navigateToRoute(): void {
    this.router.navigate(this.questionsRoute);
  }

  openWhatsApp() {
    window.open(
      'https://api.whatsapp.com/send?phone=+59161597086&text=Quiero un préstamo',
      '_blank'
    );
  }

  calculate() {
    this.totalInvest = Number(
      (this.monto * 0.02 + this.monto / this.plazo).toFixed(2)
    );
    this.totalgain = this.totalInvest * this.plazo;
    this.percentageTotal = this.plazo * 2;
    setTimeout(() => {
      this.myChartComponent.total = this.totalgain;
      this.myChartComponent.percentage = this.percentageTotal;
      this.myChartComponent.updateChart();
    }, 100);
  }

  calculateAndScroll() {
    this.validateInputs();
    if (this.isValid) {
      this.showTableInvest = true;
      const tableContainer = document.getElementById('table-container');
      if (tableContainer) {
        this.calculate();
        tableContainer.scrollIntoView({ behavior: 'smooth' });
      }
      this.scroll('table-container');
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.language = params['language'] || 'es';
      this.updateQuestionsRoute();
    });
  }

  updateQuestionsRoute(): void {
    if (this.language === 'es') {
      this.questionsRoute = ['/', this.language, 'preguntas-frecuentes'];
    } else {
      this.questionsRoute = ['/', this.language, 'frequent-questions'];
    }
  }
}
