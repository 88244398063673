import { Component, OnInit, AfterViewInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {trigger, state, style, animate, transition, stagger, query } from "@angular/animations"
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('bannerTrigger', [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class BannerComponent implements OnInit {
  responsiveMenuVisible: boolean = false;
  selectedQuestion: any
  isMobile: boolean = false;
  imagePosition: string = 'right';
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkViewport();
  }  


  executeFunction(functionString: string): void {
    const funcMatch = functionString.match(/(\w+)\((.*?)\)/);
    if (funcMatch) {
      const funcName = funcMatch[1];
      const funcArgs = funcMatch[2] ? funcMatch[2].split(',').map(arg => arg.trim().replace(/['"]/g, '')) : [];
      if (typeof this[funcName] === 'function') {
        this[funcName](...funcArgs);
      }
    }
  }
  
  checkViewport() {
    this.isMobile = window.innerWidth <= 768;
    this.imagePosition = this.isMobile ? 'right' : 'left';
  }
  constructor(
    private router: Router,
    public analyticsService: AnalyticsService
  ) { 
    this.checkViewport();
  }
  


  ngOnInit(): void { 
  }
  
  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }
    this.responsiveMenuVisible=false;
  }
  
}
