<!-- <div class="card">
	<div class="card-inner">
		<div class="card-front">
			<p></p>
			<div class="icon-container">
				<i class="{{ icon }}"></i>
			</div>
		</div>
		<div class="card-back">
			<p></p>
			<a routerLink="['/pregunta-frecuentes']"
				routerLinkActive="router-link-active"
				class="learn-more-btn">
				{{"Generico.VerMas" | translate}}
			</a>
		</div>
	</div>
</div> -->




<div class="platform-advantages-container">
	<div class="advantages">
		<div class="advantage">
			<i class="material-icons">
				{{ iconClass }}
				<!-- devices -->
			</i>
			<h5>{{tittle}}</h5>
			<p class="text-center">{{description}}</p>
		</div>
		<!-- <div class="advantage">
			<i class="material-icons">security</i>
			<h5>Seguridad</h5>
			<p>
				Garantizamos la seguridad de tus transacciones y tu información personal.
			</p>
		</div>
		<div class="advantage">
			<i class="material-icons">insights</i>
			<h5>Sencillez</h5>
			<p>
				Hemos optimizado cada paso de la solicitud para que sea claro y directo.
			</p>
		</div> -->
	</div>
</div>
