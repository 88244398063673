import { Component, Input } from '@angular/core';

@Component({
  selector: 'mision-vision',  
  templateUrl: './mision-vision.component.html',
  styleUrl: './mision-vision.component.scss'
})
export class MisionVisionComponent {
  @Input() imageUrl: string ;
  @Input() title: string | undefined;
  @Input() greenTitle: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() alignLeft: boolean = false;
  @Input() imagePosition: 'left' | 'right';
  @Input() showRegisterButton: boolean = false;

}