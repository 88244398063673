<section class="section banner">
	<div *ngFor='let slide of "slides" |translate; let i = index'
		class="container d-flex">
		<div class="col-md-8 banner-section">
			<h2 [innerHTML]="slide.title | translate"
				class="mt-4"></h2>
			<p [innerHTML]="slide.description | translate"
				class="mt-4 mb-4 text-context"></p>
			<div class="container-buttons mt-4">
				<a *ngIf="slide.functionBtn1; else noFuncBtn1"
					(click)="executeFunction(slide.functionBtn1)"
					class="second-btn">
					{{slide.actionBtn1 | translate}}
				</a>
				<a *ngIf="slide.functionBtn2; else noFuncBtn2"
					(click)="executeFunction(slide.functionBtn2)"
					class="third-btn btn-second">
					{{slide.actionBtn2 | translate}}
				</a>
			</div>
		</div>
		<!-- <div class="col-md-4 banner-image">
			<img src="assets/images/banner.png"
				class="img-content"
				alt>
		</div> -->
	</div>
</section>
<!-- <div class="container">
	<div class="check-section">
		<div *ngFor='let checkList of "Banner.CheckList" | translate; let i = index'
			class="elements d-flex align-items-center mr-3">
			<div class="step-icon">
				<span class="material-icons">{{checkList.image}}</span>
			</div>
			<h5 class="mb-0 ml-4">{{checkList.text}}</h5>
		</div>
	</div>
</div> -->
