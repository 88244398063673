<!-- <footer class="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-4">
				<div class="column column-left mb-2">
					<a routerLink="index mb-2">
						<img class="img-logo-footer"
							src="assets/images/logo.png"
							alt="logo-prestaya">
					</a>
					<div class="text-footer">
						<p class="mb-2">
							{{"Footer.Text1" | translate}}
						</p>
						<p class="green-color mb-2">
							<strong>
								{{"AboutMe.Celphone" | translate}}
							</strong>
						</p>
					</div>
				</div>
			</div>

			<div class="col-md-4">
				<div class="column mb-2">
					<p class="follow-us green-color">
						{{"Footer.Siguenos" | translate}}
					</p>
					<div class="iconos">
						<div class="icono">
							<a href="https://twitter.com"
								target="_blank">
								<i class="fab fa-twitter icono-img"></i>
							</a>
						</div>
						<div class="icono">
							<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
								target="_blank">
								<i class="fab fa-facebook icono-img"></i>
							</a>
						</div>
						<div class="icono">
							<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
								target="_blank">
								<i class="fab fa-instagram icono-img"></i>
							</a>
						</div>
						<div class="icono">
							<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
								target="_blank">
								<i class="fab fa-whatsapp icono-img"></i>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-4">
				<div class="column mb-2">
					<p class="green-color mb-2">
						{{"Footer.Download" | translate}}
					</p>
					<a href="#"
					target="_blank"
					class="w-inline-block">
					<img class="img-download"
					src="https://assets-global.website-files.com/60fee83d8392d802267e7efc/60fee83d8392d80b8e7e7f76_playstore.svg"
					loading="lazy"
					alt="Play Store">
				</a>
				<a href="#"
						target="_blank"
						class="w-inline-block">
						<img class="img-download"
							src="https://assets-global.website-files.com/60fee83d8392d802267e7efc/60fee83d8392d83e687e7f77_appstore.svg"
							loading="lazy"
							alt="App Store">
						</a>
					</div>
			</div>
		</div>
		<hr class="divider">
		<div class="row">
			<div class="col-md-4">
				<div class="container-legal">
					<a href="https://drive.google.com/file/d/1kYR8-5dDkRCUW1v7td7h6yxO_vzC787N/view?usp=sharing"
					target="_blank">
						<p>
							<span class="privacy">
								{{"Footer.Privacy" | translate}}
							</span>
						</p>
					</a>
				</div>
			</div>
			<div class="col-md-4">
				<div class="container-legal justify-content-center">
					<a href="https://drive.google.com/file/d/1kYR8-5dDkRCUW1v7td7h6yxO_vzC787N/view?usp=sharing"
						target="_blank">
						<p>
							<span class="terms">
								{{"Footer.Codition" | translate}}
							</span>
						</p>
					</a>
				</div>
			</div>
			<div class="col-md-4 text-center">
				<p>
					{{"Footer.DerechosReservados" | translate}}
				</p>
			</div>
		</div>
	</div>
</footer> -->
<!-- <footer class="footer">
	<div class="footer-container">
		<div class="footer-left">
			<img class="img-logo-footer"
			src="assets/images/logo.png"
			alt="logo-prestaya">
			<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
			class="social-icon">
			<i class="fab fa-facebook-f"></i>
		</a>
		<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
		class="social-icon">
		<i class="fab fa-instagram"></i>
	</a>
	<a href="https://www.linkedin.com"
	class="social-icon">
	<i class="fab fa-linkedin-in"></i>
</a>
</div>
<div class="footer-right">
	<a href="#"
	class="footer-link">
	{{"Footer.Codition" | translate}}
</a>
<iframe src="../../../../assets/pdf/POLITICAS DE PRIVACIDAD - APP PRESTAYA.pdf"
frameborder="0"
class="footer-link">
</iframe> 
<a (click)="navigateToRoute()"
target="_blank"
class="footer-link">
{{"Footer.Privacy" | translate}}
</a>
<a href="#"
class="footer-link">
Preguntas frecuentes
</a> 
</div>
</div>
<div class="footer-bottom">
	<p>
		<!-- GENCORP SRL © 2023 | NIT y Matricula Comercial: 455158022 | Todos los derechos reservados | Av. Landaeta a media cuadra de la Facultad de Agronomía, Edificio Ohana # 522 mezzanine. Of. # 10, La Paz-Bolivia 
		{{"Footer.DerechosReservados" | translate}}
	</p>
</div>
</footer> -->
<footer class="footer">
	<div class="container">
		<div class="footer-top">
			<div class="footer-logo2">
				<img src="../../../../assets/images/footer.png"
					alt>
				<!-- <span>Presta</span>
				<span class="logo-highlight">Ya</span> -->
			</div>
			<div class="footer-social2">
				<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
					target="_blank">
					<i class="fab fa-facebook"></i>
				</a>
				<a href="https://www.linkedin.com/company/prestayabo/"
					target="_blank">
					<i class="fab fa-linkedin"></i>
				</a>
				<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
					target="_blank">
					<i class="fab fa-instagram"></i>
				</a>
			</div>
		</div>
		<div class="footer-columns">
			<div class="footer-column">
				<h3>
					{{"AboutMe.FooterTitle" | translate}}
				</h3>
				<ul>
					<li>
						<a (click)="scroll('lends')">
							{{"Header.Item2" | translate}}
						</a>
					</li>
					<li>
						<a (click)="scroll('calc')">
							{{"Header.Item1" | translate}}
						</a>
					</li>
					<li>
						<a (click)='navigateToContactRoute()'>
							{{"Header.Item3" | translate}}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer-column">
				<h3>
					{{"AboutMe.FooterTitle2" | translate}}
				</h3>
				<ul>
					<li>
						<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
							target="_blank">
							{{"AboutMe.FooterSubTitle2" | translate}}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer-column">
				<h3>
					{{"Footer.FooterTitle4"| translate}}
				</h3>
				<ul>
					<li>
						<a (click)="navigateToTermsRoute()">
							{{"Footer.Codition" | translate}}
						</a>
					</li>
					<li>
						<a (click)="navigateToRoute()">
							{{"Footer.Privacy"| translate}}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer-column">





				<h3>
					{{"Footer.FooterTitle3"| translate}}
				</h3>
				<ul>
					<li>
						<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
							target="_blank">
							{{"AboutMe.Celphone" | translate}}
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="footer-logo">
				<img src="../../../../assets/images/footer.png"
					alt>
				<!-- <span>Presta</span>
				<span class="logo-highlight">Ya</span> -->
			</div>
			<div class="footer-rights">
				<p>
					{{"Footer.DerechosReservados"| translate}}
				</p>
			</div>
			<div class="footer-social">
				<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
					target="_blank">
					<i class="fab fa-facebook"></i>
				</a>
				<a href="https://www.linkedin.com/company/prestayabo/"
					target="_blank">
					<i class="fab fa-linkedin"></i>
				</a>
				<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
					target="_blank">
					<i class="fab fa-instagram"></i>
				</a>
			</div>
		</div>
	</div>
</footer>
