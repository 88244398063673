import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sectionImage',
  templateUrl: './sectionImage.component.html',
  styleUrls: ['./sectionImage.component.scss']
})
export class SectionImageComponent implements OnInit {
  mostrarBoton: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 1000) {
      this.mostrarBoton = true;
    } else {
      this.mostrarBoton = false;
    }
  }

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
