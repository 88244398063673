<main>
	<app-banner></app-banner>
	<app-steps></app-steps>
	<app-about></app-about>
	<!-- <app-sectionImage></app-sectionImage> -->
	<!-- <app-proyects></app-proyects> -->
	<!-- <app-more-proyects></app-more-proyects> -->
	<!-- <app-aboutUs></app-aboutUs> -->
	<app-questions></app-questions>
	<div class="container">
		<app-sectionVentajas></app-sectionVentajas>
	</div>
	<app-sectionEmpresas></app-sectionEmpresas>
	<app-buttonUp></app-buttonUp>
</main>
