import { Component, OnInit, Inject, Renderer2, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';

interface FaqItem {
  question: string;
  answer: string;
  expanded: boolean;  
}

@Component({
    selector: 'app-questions',
    templateUrl: './frequent-questions.component.html',
    styleUrls: ['./frequent-questions.component.scss']
})
export class QuestionsComponent implements OnInit{
  ngOnInit(): void {    
  }  
  expandedIndex: number | null = null;
  showAll: boolean = false;

  toggleAnswer(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  toggleView(): void {
    this.showAll = !this.showAll;
  }
}

