import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-card-lend',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardLendComponent implements OnInit {    
    @Input() amount;
    @Input() time;
    @Input() totalAmount;
    @Input() percentage;
    language: string;

    private _total: number;
    contactsRoute: string[];
    languageFormControl: UntypedFormControl= new UntypedFormControl();

    @Input()
    set total(value: number) {
        this._total = parseFloat(value.toFixed(2));
    }

    @ViewChild('popupDialog') popupDialog: ElementRef<HTMLDialogElement>;

  openPopup() {
    this.popupDialog.nativeElement.showModal();
  }

  closePopup() {
    this.popupDialog.nativeElement.close();
  }
    
    get total(): number {
        return this._total;
    }

    constructor(
      private router: Router,      
      private route: ActivatedRoute,
      public analyticsService: AnalyticsService,
      public languageService: LanguageService
    ) {
    }

    ngOnInit() { 
      this.languageFormControl.valueChanges.subscribe(val => this.languageService.changeLanguage(val))

      this.languageFormControl.setValue(this.languageService.language)
      this.route.params.subscribe(params => {
        this.language = params['language'] || 'es';
        this.updateContactRoute();
      });                  
    }    

    navigateToRoute(): void {
      this.router.navigate(this.contactsRoute);     
      this.scrollToTop();
    }
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });      
    }
    
    updateContactRoute(): void {
      if (this.language === 'es') {
        this.contactsRoute = ['/', this.language, 'nosotros'];
      } 
      if  (this.language === 'en') {
        this.contactsRoute = ['/', this.language, 'about-Us'];
      }    
    }
}
