<div class="landing-section">
	<div class="content">
		<div class="features">
			<img src="../../../../assets/images/contact.jpeg"
				alt>
		</div>
		<div class="intro">
			<div class="green-bar"></div>
			<h2>
				{{ "section.Nosotros" | translate}}
				<!-- La mejor plataforma en línea -->
			</h2>
			<p>
				{{ "section.NosotrosSubtitle" | translate}}
			</p>
		</div>
	</div>
</div>
