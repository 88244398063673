import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-card-comentario',
    templateUrl: './card-comentario.component.html',
    styleUrls: ['./card-comentario.component.scss']
})
export class CardComentarioComponent implements OnInit {    
    @Input() imagen: string;
    @Input() author: string;
    @Input() text: string; 

    constructor() {        
    }

    ngOnInit() {                
    }    
}
