<section class="section proyects"
	id='proyects'>
	<div class="container">
		<div class="section-box">
			<div data-aos="fade-up"
				data-aos-duration="1000">
				<h2 class="section-title">
					<span class='n-section-title'>03.</span>
					{{"FeatureProjects.Title" | translate}}
				</h2>
			</div>

			<div *ngFor='let project of "FeatureProjects.Projects" | translate; let i = index'
				class="d-flex proyect-container"
				data-aos="fade-up"
				data-aos-duration="1000">
				<owl-carousel-o *ngIf="i%2==0"
					[options]="customOptions"
					class='owl-carousel-o'>
					<ng-container *ngFor="let img of project.imgs">
						<ng-template [id]="project.Title+slide+i"
							carouselSlide>
							<div #imgContainer
								class='img-container'>
								<img [src]="img"
									[alt]="project.Title"
									class='img-feature-proyect rounded'>
							</div>
						</ng-template>
					</ng-container>
				</owl-carousel-o>
				<div *ngIf="i%2==0"
					class="img-feature-proyect-container"
					data-aos="fade-up"
					data-aos-duration="1000">
					<div class="img-feature-proyect-box">
						<div class="img-container"
							style='width: auto;'>
							<img [src]="project.imgs[0]"
								[alt]="project.imgs[0]"
								class='img-feature-proyect rounded'
								width='600px'>
						</div>
					</div>
				</div>
				<div [class.proyect-info-right]='i%2==0'
					[class.proyect-info-left]='i%2!=0'
					class="h-100"
					style="background-image: url('{{project.img}}');">
					<div class="proyect-box">
						<h4 [class.text-right]='i%2==0'
							[class.text-left]='i%2!=0'
							class="feature-proyect">
							{{"FeatureProjects.Label" | translate}}
						</h4>
						<h5 [class.text-right]='i%2==0'
							[class.text-left]='i%2!=0'
							class="proyect-name">
							{{project.Title}}
						</h5>

						<div (click)='debug()'
							class="proyect-description-box">
							<p [innerHTML]="project.Description"
								class="mb-0">
							</p>
						</div>
						<ul [class.justify-content-end]='i%2==0'
							[class.justify-content-start]='i%2!=0'
							class="proyect-skills">
							<li *ngFor='let technology of project.Technologies'
								class="ml-3">
								<span class="underline technology">{{technology}}</span>
							</li>
						</ul>
						<div [class.text-right]='i%2==0'
							[class.text-left]='i%2!=0'
							class="proyect-links">
							<a *ngIf="project['ghLink']"
								[href]="project['ghLink']"
								style='color: inherit'
								target="_blank">
								<i class="ml-3 fab fa-github"></i>
							</a>
							<a *ngIf="project['demoLink']"
								[href]="project['demoLink']"
								style='color: inherit'
								target="_blank">
								<i class="ml-3 fas fa-external-link-alt"></i>
							</a>
						</div>
					</div>
				</div>
				<owl-carousel-o *ngIf="i%2!=0"
					[options]="customOptions"
					class='owl-carousel-o'>
					<ng-container *ngFor="let img of project.imgs">
						<ng-template [id]="project.Title+slide+i"
							carouselSlide>
							<div class='img-container'>
								<img [src]="img"
									[alt]="project.Title"
									class='img-feature-proyect rounded'>
							</div>
						</ng-template>
					</ng-container>
				</owl-carousel-o>
				<div *ngIf="i%2!=0"
					class="img-feature-proyect-container"
					data-aos="fade-up"
					data-aos-duration="1000">
					<div class="img-feature-proyect-box">
						<div class="img-container"
							style='width: auto;'>
							<img [src]="project.imgs[0]"
								[alt]="project.imgs[0]"
								class='img-feature-proyect rounded'
								width='600px'>
						</div>
					</div>
				</div>
			</div>
			<div></div>
		</div>
	</div>
</section>
