<section class="section about"
	id='aboutUs'>
	<div class="banner">
		<div *ngFor='let slide of "Contactview" |translate; let i = index'
			class="container d-flex">
			<div class="col-md-4 banner-left"></div>
			<div class="col-md-6 banner-section">
				<h2 [innerHTML]="slide.title | translate"></h2>
				<img class="img-qr mt-4"
					src="../../../../assets/images/soloQR.png"
					alt>
				<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
					target="_blank">
					<p [innerHTML]='"whatsappNumber2" | translate'
						class="mt-2 whatsappnumber">
					</p>
				</a>
				<a href="https://play.google.com/store/apps/details?id=com.prestaya.prestaya"
					target="_blank"
					class="play-store-button">
					<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
						alt="Get it on Google Play">
				</a>
			</div>
		</div>
	</div>
	<div class="container">
		<app-sectionNosotros></app-sectionNosotros>
	</div>
	<!-- [title2]='"Banner.visionTittle" | translate'
	[subtitle2]='"Banner.visionContent" | translate' -->
	<div class="container">
		<mision-vision [title]='"Banner.visionTittle" | translate'
			[subtitle]='"vision"'
			[imagePosition]="'right'"
			[subtitle]='"Banner.visionContent" | translate'
			[imageUrl]='"Banner.visionUrl" | translate'
			data-aos="fade-up">
		</mision-vision>
	</div>
	<div class="container">
		<mision-vision [title]='"Banner.misionTittle" | translate'
			[subtitle]='"Banner.misionContent" | translate'
			[imagePosition]="imagePosition"
			[subtitle]='"Banner.misionContent" | translate'
			[imageUrl]='"Banner.misionUrl" | translate'
			[ngClass]="{'image-left': isMobile, 'image-right': !isMobile}"
			data-aos="fade-up">
		</mision-vision>
	</div>
	<div class="container">
		<app-contact></app-contact>
	</div>
	<app-sectionEmpresas></app-sectionEmpresas>
	<div class="container-section-app">
		<app-sectionApp></app-sectionApp>
	</div>
</section>
