import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: "es" | "en";

  constructor(
    public translateService: TranslateService,
    private router: Router,
  ) {}

  initLanguage(){
    this.translateService.addLangs(["en", "es"]);
    let language = navigator.language || (navigator as any).userLanguage;
    language = language.split("-").includes("es") ? "es" : "en";
    this.translateService.setDefaultLang(language);

    // Navigate to the default language route:
    this.router.navigate([language]);
    this.language = language;
  }

  changeLanguage(language: "es" | "en"){
    this.translateService.setDefaultLang(language);
    this.router.navigate([language]);
    this.language = language;
  }
}
