<section class="trusted-companies">
	<div class="green-bar"></div>
	<h2 [innerHTML]='"contact.empresas" | translate'
		class="title mb-4"></h2>
	<div class="logos mt-4 mb-4">
		<div class="logo-item">
			<img src="../../../../assets//images/empresas/digicert.png"
				alt="Digicert">
		</div>
		<div class="logo-item">
			<img src="../../../../assets/images/empresas/infocred.png"
				alt="Infocred BI S.A.">
		</div>
		<div class="logo-item">
			<img src="../../../../assets//images/empresas/segip.png"
				alt="Segip">
		</div>
		<!-- <div class="logo-item">
			<img src="../../../../assets//images/empresas/sintesis.png"
				alt="Sintesis">
		</div> -->
	</div>
</section>
