<!-- <div class="contact-section">
	<span [innerHTML]='"Contact.Question" | translate'
		class="mt-2 steps-title"></span>
	<h2 [innerHTML]='"Contact.Title" | translate'
		class="mt-2 "></h2>
	<div class="contact-info">
		<div class="contact-item">
			<h3 [innerHTML]='"whatsappNumber.title" | translate'
				class="mt-2"></h3>
			<p>
				{{ "whatsappNumber" | translate }}
			</p>
		</div>
		<!-- <div class="contact-item">
			<h3 [innerHTML]='"ubication.Title" | translate'
				class="mt-2"></h3>
			<p>
				{{ "ubication" | translate }}
			</p>
		</div>
		<div class="contact-item">
			<h3 [innerHTML]='"time.Title" | translate'
				class="mt-2 "></h3>
			<p>
				{{ "time" | translate }}
			</p>
		</div>
	</div>
	<div class="contact-map">
		<!-- <img src="ruta-a-tu-mapa.png"
			alt="Mapa de ubicación">
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0192910760555!2d-122.41941548469257!3d37.77492927975827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085818f9f4c9b81%3A0x4f3b3e9d0e2fd922!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1608219316464!5m2!1sen!2s"
			style="border:0;"
			allowfullscreen
			loading="lazy"></iframe>
	</div>
</div> -->










<section class="contact-section">
	<span [innerHTML]='"Contact.Question" | translate'
		class="mt-2 green-bar"></span>
	<h2 [innerHTML]='"Contact.Title" | translate'
		class="mt-2 mb-4"></h2>
	<div class="contact-content">
		<div class="text-contact">
			<p class="description">
				{{ "section.ContactTitle" | translate}}
			</p>
			<div class="contact-info">
				<h3>
					{{ "time.Title" | translate}}
				</h3>
				<p>
					{{ "time" | translate}}
				</p>
				<h3>
					{{ "whatsappNumber.title" | translate}}
				</h3>
				<p>
					{{ "whatsappNumber" | translate}}
				</p>
			</div>
		</div>
		<div class="contact-image">
			<img src="../../../../assets/images/qr-code.jpeg"
				alt="Código QR para WhatsApp">
		</div>
	</div>
</section>
