<section class="section about"
	id='about'>
	<div class="container"
		id="calc">
		<div class="green-bar"></div>
		<h2 [innerHTML]='"Lend.Title" | translate'
			class="text-center mb-5"></h2>
		<div class="container-lend">
			<div class="col-md-6 ">
				<div class="container-labels mb-2">
					<div class="form-group">
						<label for="time">
							{{"Generico.Prestamo" | translate}}
							<div class="input-section">
								<input [(ngModel)]="monto"
									(input)="updateSliderColor(); validateInputs()"
									type="number"
									min="100"
									max="14000"
									class="editable">
								<span class="currency">Bs</span>
							</div>
						</label>
					</div>
					<div *ngIf="montoError"
						class="text-red-500">
						<p class="alert-text">{{ montoError }}</p>
					</div>
					<!-- <div class="flex items-center mb-4">
						<input [(ngModel)]="monto"
							[style.background]="sliderBackgroundMonto"
							(input)="updateSliderColor(); validateInputs()"
							id="range1"
							type="range"
							min="100"
							max="14000"
							step="100"
							class="w-full h-2 appearance-none cursor-pointer">
					</div> -->
					<!-- <div class="input-range-label mb-2">
							<span>100</span>
							<span>14000</span>
						</div> -->
					<div class="form-group">
						<label for="time">
							{{"Generico.PrestamoTime" | translate}}
							<div class="input-section">
								<input [(ngModel)]="plazo"
									(input)="updateSliderColor(); validateInputs()"
									type="number"
									min="100"
									max="14000"
									class="timeControler">
								<span class="currency">Meses</span>
							</div>
						</label>
					</div>
					<div class="flex items-center ">
						<input [(ngModel)]="plazo"
							[style.background]="sliderBackgroundPlazo"
							(input)="updateSliderColor(); validateInputs()"
							id="range2"
							type="range"
							min="1"
							max="24"
							step="1"
							class="w-full h-2 appearance-none cursor-pointer">
					</div>
					<div *ngIf="plazoError"
						class="text-red-500">
						<p class="alert-text">{{ plazoError }}</p>
					</div>
				</div>
				<div>
					<button (click)="calculateAndScroll()"
						class="invest-btn mt-4 mb-2">
						{{"AboutMe.Actionbtn" | translate}}
					</button>
				</div>
				<!-- <div class="input-range-label">
						<span>1</span>
						<span>24</span>
					</div> -->
			</div>
			<div class="col-md-4">
				<app-card [amount]="monto"
					[time]="plazo"
					[percentage]="percentageTotal"
					[totalAmount]="totalInvest"
					[total]="totalgain"></app-card>
			</div>
		</div>
	</div>
	<div [class.hidden]="!showTableInvest"
		class="container justify-content-center"
		id="table-container">
		<div *ngIf="showTableInvest"
			class="table-invest">
			<!-- <h2 [innerHTML]='"AboutMe.GraficoT" | translate'
				class="text-center"></h2> -->
			<app-my-chart [amount]="monto"
				[time]="plazo"
				[percentage]="percentageTotal"
				[total]="totalgain"
				class="mb-4"
				id="myChartComponent"></app-my-chart>
		</div>
	</div>
	<div class="requirements-container">
		<span [innerHTML]='"requerements.Title" | translate'
			class="requirements-title steps-title mb-4"></span>
		<h4 [innerHTML]='"requerements.SubTitle" | translate'
			class="requirements-subtitle mt-4"></h4>
		<div class="requirements">
			<div *ngFor="let item of 'requerements.text' | translate; let i = index"
				class="container-requeriments">
				<div class="requirement">
					<div class="container-icon">
						<i class="material-icons">{{item.icon}}</i>
					</div>
					<h5>{{item.title}}</h5>
					<p>
						{{item.description}}
					</p>
				</div>
			</div>
		</div>
	</div>
</section>
<app-lend-section></app-lend-section>




<app-jobs></app-jobs>




<div class="container ventajas-box">
	<span [innerHTML]='"Ventajas.SubTittle"| translate'
		class="advantages-title text-center mb-4"></span>
	<h4 [innerHTML]='"Ventajas.Tittle" | translate'
		class="advantages-subtitle text-center"></h4>
	<!-- <h3 class="">¿QUÉ OFRECEMOS?</h3>
	<h4 class="">
		Ventajas de nuestra plataforma
	</h4> -->
	<!-- <h2 [innerHTML]='"Ventajas.Tittle" | translate'
		class="mt-2"></h2> -->
	<div class="ventajas-list">
		<div *ngFor="let item of 'Ventajas.List' | translate; let i = index">
			<app-ventajas [tittle]="item.text"
				[description]="item.description"
				[iconClass]="item.iconClass">
			</app-ventajas>
		</div>
	</div>
</div>
<!-- <div class="container">
	<app-sectionVentajas></app-sectionVentajas>
</div> -->
