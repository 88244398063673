import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-ventajas',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardVentajasComponent implements OnInit {    
    @Input() tittle: string;
    @Input() iconClass;
    @Input() description: string;

    constructor() {
    }

    ngOnInit() {                
    }
        
}
