import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionEmpresas',  
  templateUrl: './sectionEmpresas.component.html',
  styleUrl: './sectionEmpresas.component.scss'
})
export class SectionEmpresasComponent {  
  
}
