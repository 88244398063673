<section class="section"
	id="jobs">
	<!-- <app-lend></app-lend> -->
	<!-- <div class="container jobs">
		<div class="section-box">
			<div class="about-title mb-5"
				data-aos="fade-up"
				data-aos-duration="1000">
				<h2 [innerHTML]='"Experience.Title" | translate'
					class="mt-2"></h2>
			</div>
			<div class="loan-step-container"
				data-aos="fade-up"
				data-aos-duration="1000">
				<ul #nav="ngbNav"
					[(activeId)]="activeStep"
					ngbNav
					class="nav-tabs loan-tabs">
					<li *ngFor='let step of "Experience.Jobs" | translate; let i = index'
						[ngbNavItem]="i">
						<a ngbNavLink>
							<div [class.active]="i === activeStep"
								class="step">
								{{ i + 1 }}
							</div>
							<span [class.active]="i === activeStep">{{ step.label }}</span>
						</a>
						<ng-template ngbNavContent>
							<div class="step-content">
								<img [src]="step.image"
									alt="Illustration">
								<div class="text-content">
									<h5 [innerHTML]="step.Title | translate"
										class="mt-2 mb-2"></h5>
									<p class="text-steps">
										{{ step.Description }}
									</p>
								</div>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav"
					class="mt-2"></div>
			</div>
		</div>
	</div> -->
	<!-- <div class="requirements-container">
		<span class="requirements-title steps-title">REQUERIMIENTOS</span>
		<h4 class="requirements-subtitle">
			¿Qué necesitas para solicitar un préstamo o una inversión?
		</h4>
		<div class="requirements">
			<div class="requirement">
				<div class="container-icon">
					<i class="material-icons">account_circle</i>
				</div>
				<h5>Mayoría de edad</h5>
				<p>
					Debes tener al menos 18 años para solicitar un préstamo o inversión.
				</p>
			</div>
			<div class="requirement">
				<div class="container-icon">
					<i class="material-icons">account_balance</i>
				</div>
				<h5>Cuenta bancaria</h5>
				<p>
					Debes ser titular de una cuenta bancaria activa para procesar tu solicitud.
				</p>
			</div>
			<div class="requirement">
				<div class="container-icon">
					<i class="material-icons">phone_iphone</i>
				</div>
				<h5>Línea de celular</h5>
				<p>
					Necesitas una línea de celular para nuestra comunicación.
				</p>
			</div>
			<div class="requirement">
				<div class="container-icon">
					<i class="material-icons">bar_chart</i>
				</div>
				<h5>
					Información financiera
				</h5>
				<p>
					Presenta tus ingresos y estados financieros para evaluar tu situación.
				</p>
			</div>
		</div>
	</div> -->
</section>
<div class="faq-section">
	<h5 [innerHTML]='"Jobs.TitleSection" | translate'
		class="mt-2 mb-4 text-center text-question"></h5>
	<h2 [innerHTML]='"Jobs.Title" | translate'
		class="mt-2 mb-5 text-center"></h2>
	<!-- <p class="subtitle mb-4">
		{{ "Comentarios.Content" | translate }}
	</p> -->
	<div class="container">
		<div class="comentarios-wrapper">
			<app-card-comentario *ngFor="let comentario of comentarios.slice(currentIndex, currentIndex + 3)"
				[imagen]="'assets/images/personas/' + comentario.number + '.jpg'"
				[author]="comentario.author"
				[text]="comentario.reviewText">
			</app-card-comentario>
		</div>
		<div class="pagination">
			<span *ngFor="let i of getPaginationArray()"
				[class.active]="i === (currentIndex / 3)"
				(click)="setPage(i)">
			</span>
		</div>
	</div>
	<!-- <div class="d-flex section-comentario-form">
		<h2 [innerHTML]='"Comentarios.Subtittle" | translate'
			class="mt-2 mb-4 text-center"></h2>
		<p class="mb-4 coment-text">
			{{ "Comentarios.TextContent" | translate }}
		</p>
		<form [formGroup]="commentForm"
			(ngSubmit)="onSubmit()"
			class="comment-box">
			<label for="comment">
				{{"Coment.Text" | translate}}
			</label>
			<textarea id="comment"
				formControlName="comment"></textarea>
			<div *ngIf="commentForm.get('comment').invalid && (commentForm.get('comment').touched || commentForm.get('comment').dirty)">
				<div *ngIf="commentForm.get('comment').hasError('required')"
					class="alert-text">
					{{"Message.ComentRequire" | translate}}
				</div>
				<div *ngIf="commentForm.get('comment').hasError('minWords')"
					class="alert-text">
					{{"Message.ComentMin" | translate}}
				</div>
				<div *ngIf="commentForm.get('comment').hasError('maxWords')"
					class="alert-text">
					{{"Message.ComentMax" | translate}}
				</div>
			</div>
			<button [disabled]="commentForm.invalid"
				[disabled]="isSubmitting"
				class="submit-btn"
				type="submit">
				{{"Generico.Send" | translate}}
			</button>
			<div *ngIf="message"
				class="alert alert-success mt-3">
				{{ message }}
			</div>
		</form>
	</div> -->
</div>
<!-- <div class="container">
	<h2 [innerHTML]='"faq.QuestionTittle" | translate'
		class="mt-2 mb-4"></h2>
	<app-questions></app-questions>
</div> -->
