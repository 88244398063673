import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionNosotros',  
  templateUrl: './sectionNosotros.component.html',
  styleUrl: './sectionNosotros.component.scss'
})
export class SectionNosotrosComponent {  
  
}
