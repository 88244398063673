<div class="statistics-section">
	<div class="stat-item">
		<img src="assets/images/prueba/fondo-invest.png"
			alt="Person 1">
		<p>
			+3 millones
			<br>
			<span>
				+3 millones de usuarios registrados
			</span>
		</p>
	</div>
	<div class="stat-item">
		<img src="assets/images/prueba/fondo-invest.png"
			alt="Person 2">
		<p>
			96.7%
			<br>
			<span>
				El 96.7% de nuestros acreditados pagan a tiempo
			</span>
		</p>
	</div>
	<div class="stat-item">
		<img src="assets/images/prueba/fondo-invest.png"
			alt="Person 3">
		<p>
			8.9%
			<br>
			<span>
				Tasas desde 8.9% para nuestros acreditados
			</span>
		</p>
	</div>
	<div class="stat-item">
		<img src="assets/images/prueba/fondo-invest.png"
			alt="Person 4">
		<p>
			+3,000 millones
			<br>
			<span>
				+3,000 millones en préstamos entregados
			</span>
		</p>
	</div>
	<div class="stat-item">
		<img src="assets/images/prueba/fondo-invest.png"
			alt="Person 5">
		<p>
			17.49%
			<br>
			<span>
				Rendimiento promedio de 17.49% anual para nuestros inversionistas
			</span>
		</p>
	</div>
</div>
