<div class="card-container">
	<div class="card">
		<p [innerHTML]='"Invest.Title" | translate'></p>
		<h1>{{total}} Bs</h1>
	</div>
	<div class="details-card">
		<div class="detail-item">
			<span [innerHTML]='"Generico.Prestamo" | translate'></span>
			<span>{{amount}} Bs</span>
		</div>
		<div class="detail-item">
			<span [innerHTML]='"Lend.Tasa" | translate'></span>
			<span>{{percentage}}%</span>
		</div>
		<div class="detail-item">
			<span [innerHTML]='"Invest.Time" | translate'></span>
			<span>{{time}} Meses</span>
		</div>
		<div class="detail-item">
			<span [innerHTML]='"Invest.Ganancia" | translate'></span>
			<span>{{totalAmount}} Bs</span>
		</div>
	</div>
	<a (click)='navigateToRoute()'
		target="_blank"
		class="register-btn">
		{{"Generico.Registro" | translate}}
	</a>
</div>
