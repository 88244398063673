import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations:[
    trigger("animateFooter", [
      transition(":enter", [
        query("*", [
          style({opacity: 0, transform: "translateY(100%)"}),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity:1, transform: "none"})
            )
          ])
        ])
      ])
    ])
  ]
})
export class FooterComponent implements OnInit {
  language: any;
  contactsRoute: string[];
  politicsRoute: string[];
  termsRoute: string[];
  responsiveMenuVisible: Boolean = false;

  constructor(
    public analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {   
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.language = params['language'] || 'es';
      this.updateContactRoute();
      this.updateContactsRoute();
      this.updateTermsRoute();
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });    
  }
  
  navigateToRoute(): void {
    this.router.navigate(this.contactsRoute);
    this.scrollToTop();
  }
  navigateToTermsRoute(): void {
    this.router.navigate(this.termsRoute);
    this.scrollToTop();
  }
  
  navigateToContactRoute(): void {
    this.router.navigate(this.politicsRoute);
    this.scrollToTop();
  }
  scroll(el) {
    if (document.getElementById(el)) {
      const elementPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: elementPosition, behavior: 'smooth' });
    } else {
      this.router.navigate(['/',this.language ]).then(() => {
        const elementPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: elementPosition, behavior: 'smooth' });
      });
    }
    this.responsiveMenuVisible = false;
  }
  updateContactRoute(): void {
    if (this.language === 'es') {
      this.contactsRoute = ['/', this.language, 'politicas-de-privacidad'];
    } else {
      this.contactsRoute = ['/', this.language, 'politicas-de-privacidad'];
    }    
  }
  updateContactsRoute(): void {
    if (this.language === 'es') {
      this.politicsRoute = ['/', this.language, 'nosotros'];
    } 
    if  (this.language === 'en') {
      this.politicsRoute = ['/', this.language, 'nosotros'];
    }     
  }
  updateTermsRoute(): void {
    if (this.language === 'es') {
      this.termsRoute = ['/', this.language, 'terminos-condiciones'];
    } 
    if  (this.language === 'en') {
      this.termsRoute = ['/', this.language, 'terminos-condiciones'];
    }     
  }

}
